import React from 'react';

import Page01Image from '../../assets/images/projects/spotify-top-podcasts/page-01.webp';
import Page02Image from '../../assets/images/projects/spotify-top-podcasts/page-02.webp';

import { Content, ContentImage } from '../../components/pages/projects/Content';
import {
  HeaderLink,
  Header,
  HeaderDescriptionContainer,
  HeaderDetails
} from '../../components/pages/projects/Header';
import ReturnToTop from '../../components/ReturnToTop';

import Typography from '../../components/Typography';
import Image from '../../components/Image';

const images = [
  {
    id: 'page01',
    name: 'Homepage',
    source: Page01Image,
  },
  {
    id: 'page02',
    name: 'Podcasters List',
    source: Page02Image,
  },
]

const SpotifyTopPodcasts: React.FC = () => {
  return (
    <>
      <ReturnToTop />

      <Header>
        <HeaderDetails
          name='Top Podcasters'
          href='https://spotifytoppodcasters.byspotify.com'
          role='Front-End Developer'
          client='Spotify'
          year={2021}
        />

        <HeaderDescriptionContainer>
          <Typography variant='body1' size='large'>
            Spotify is a digital music and podcast service that gives you access to millions of songs all over the world.
          </Typography>

          <Typography variant='body1' size='large'>
            Spotify Top Podcasters is a special initiative that recognizes and celebrates creators who reach the Top 10 on the Podcast Chart.
          </Typography>

          <Typography variant='body1' size='large'>
            {'Being made with '}

            <HeaderLink href='https://nuxtjs.org' target='_blank'>Nuxt</HeaderLink>

            , this was my first project at Hox, in which I worked as Front-End developer in a team of three developers.
          </Typography>

          <Typography variant='body1' size='large'>
            {'Agency: '}
            <HeaderLink href='https://hox.rs' target='_blank'>Hox.rs</HeaderLink>
          </Typography>
        </HeaderDescriptionContainer>
      </Header>

      <Content>
        {images.map(image => (
          <Image key={image.id} src={image.source} alt={image.name} />
        ))}
      </Content>
    </>
  )
}

export default SpotifyTopPodcasts;
