import { styled } from "../../../../stitches.config";
import { ExternalLink } from "../../../Link";

export const Header = styled('header', {
  display: 'flex',
  flexDirection: 'column',

  columnGap: '$6',
  marginTop: '110px',

  '@large': {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  }
})

export const HeaderDescriptionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$3',

  width: '100%',
  maxWidth: '600px',

  paddingTop: '44px', // align with project name

  p: {
    fontWeight: 400,
    color: '$grey800',
  }
})

export const HeaderLink = styled(ExternalLink, {
  color: '$commonBlack'
})
