import React, { useCallback, useEffect, useRef } from 'react';

import { ReturnTopArrow, ReturnTopContainer } from './styles';

interface Props {
  scrollToShow?: number | null
}

const defaultScrollToShow = 150

const ReturnToTop: React.FC<Props> = ({ scrollToShow }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleScroll = useCallback(() => {
    if (!containerRef.current) {
      return
    }

    const scrollTop = document.documentElement.scrollTop
    const targetScroll = scrollToShow || defaultScrollToShow

    if (scrollTop >= targetScroll) {
      containerRef.current.classList.add('active')
      containerRef.current.classList.remove('hidden')

      return
    }

    containerRef.current.classList.add('hidden')
    containerRef.current.classList.remove('active')
  }, [scrollToShow])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, { passive: true })

    return () => document.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <ReturnTopContainer
      ref={containerRef}
      className='hidden'
      onClick={handleScrollToTop}
    >
      <ReturnTopArrow />
    </ReturnTopContainer>
  )
}

export default ReturnToTop;
