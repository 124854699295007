import { spacing, styled } from "../../../../../stitches.config";

export const HeaderDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing(3)
})

export const HeaderDetailsTopic = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing(0.5),

  '.typography-body1': {
    fontSize: '1.6rem',
  },

  '.typography-body2': {
    color: '$grey500',
    fontSize: '1.6rem',
    textTransform: 'uppercase'
  },

  h2: {
    whiteSpace: 'nowrap'
  }
})
