import React from 'react';

import Typography from '../../../../Typography';
import HeaderDetailsName, { HeaderDetailsNameProps } from './HeaderDetailsName';

import { HeaderDetailsContainer, HeaderDetailsTopic } from './styles';

interface Props extends HeaderDetailsNameProps{
  role: string
  client: string
  year: number
}

const HeaderDetails: React.FC<Props> = ({ name, href, role, client, year }) => {
  return (
    <HeaderDetailsContainer>
      <HeaderDetailsTopic>
        <Typography variant='body2'>Project</Typography>
        <HeaderDetailsName name={name} href={href} />
      </HeaderDetailsTopic>

      <HeaderDetailsTopic>
        <Typography variant='body2'>Role</Typography>
        <Typography variant='body1'>{role}</Typography>
      </HeaderDetailsTopic>

      <HeaderDetailsTopic>
        <Typography variant='body2'>For</Typography>
        <Typography variant='body1'>{client}</Typography>
      </HeaderDetailsTopic>

      <HeaderDetailsTopic>
        <Typography variant='body2'>Year</Typography>
        <Typography variant='body1'>{year}</Typography>
      </HeaderDetailsTopic>
    </HeaderDetailsContainer>
  )
}

export default HeaderDetails;
