import React, { ImgHTMLAttributes, useState } from 'react';
import { keyframes, styled } from '../../stitches.config';

const skeletonLoading = keyframes({
  '0%': {
    backgroundColor: '$skeletonStartColor',
  },

  '100%': {
    backgroundColor: '$skeletonEndColor',
  },
})

const Skeleton = styled('div', {
  width: '100%',
  height: '100%',

  aspectRatio: '1228 / 620',

  animation: `${skeletonLoading} 800ms linear infinite alternate`,
})

const StyledImage = styled('img', {
  aspectRatio: '1228 / 620',
})

export type ImageProps = ImgHTMLAttributes<HTMLImageElement>

const Image: React.FC<ImageProps> = (props) => {
  const [loading, setLoading] = useState(true)

  console.log({loading});


  return (
    <>
      <StyledImage {...props}
      onLoad={() => setLoading(false)}
      hidden={loading} />
      {loading && <Skeleton />}
    </>
  )
}

export default Image;
