import { styled } from "../../../../stitches.config";

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  rowGap: '60px',
  marginTop: '120px',
})

export const ContentImage = styled('img', {

})
