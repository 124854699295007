import { fadeOut, keyframes, styled } from '../../stitches.config'
import Arrow from '../../assets/images/arrow.inline.svg'

const topBottom = keyframes({
  from: {
    transform: 'translateY(-30px)',
  },

  to: {
    transform: 'translateY(0)'
  }
})

export const ReturnTopContainer = styled('div', {
  position: 'fixed',
  right: '44px',
  bottom: '56px',

  width: '42px',
  height: '42px',
  borderRadius: '50%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  background: '$gray200',
  boxShadow: '$elevation1',

  cursor: 'pointer',
  zIndex: '$floatIndex',

  transition: 'background 200ms ease-in-out',

  '&:hover': {
    background: '$gray300',
  },

  '&.active': {
    animation: `${topBottom} 200ms`,

    opacity: 1,
    pointerEvents: 'all',

    img: {
      opacity: 1
    }
  },

  '&.hidden': {
    animation: `${fadeOut} 200ms`,

    opacity: 0,
    pointerEvents: 'none',

    img: {
      opacity: 0
    }
  },

  '@medium': {
    right: '52px',
    bottom: '64px',

    width: '44px',
    height: '44px',
  },

  '@large': {
    right: '56px',
    bottom: '72px',

    width: '46px',
    height: '46px',
  },
})

export const ReturnTopArrow = styled(Arrow, {
  width: '10px',
  height: '10px',
  transform: 'rotate(225deg)',

  '@large': {
    width: '12px',
    height: '12px',
  }
})
