import React from 'react';

import Typography from '../../../../../Typography';

import { DetailsLinkName } from './styles';

export interface HeaderDetailsNameProps {
  name: string
  href?: string
}

const HeaderDetailsName: React.FC<HeaderDetailsNameProps> = ({ name, href }) => {
  if (!href) {
    return <Typography variant='h2'>{name}</Typography>
  }

  return (
    <DetailsLinkName href={href} target='_blank'>
      <Typography variant='h2'>{name}</Typography>
    </DetailsLinkName>
  )
}

export default HeaderDetailsName;
